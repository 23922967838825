
import { Component, Vue } from 'vue-property-decorator';
import SimplePagination from '@/components/simple-pagination/simple-pagination.vue';
import { SellingPoint } from '../../entities/selling-point.entity';
import sellingpointService from '../../services/selling-points.service';

@Component({
  components: {
    SimplePagination,
  },
})
export default class SellingPointListComponent extends Vue {
  public sellingpoints: SellingPoint[] = [];

  public get headers(): any {
    const items = [
      { text: 'Nome', value: 'name', width: '40%' },
      {
        text: 'Ativo',
        value: 'activeText',
      },
      {
        text: 'Ações',
        align: 'center',
        value: 'actions',
      },
    ];

    if (this.$permissionAccess.group.isSuperAdmin) {
      items.splice(1, 0, { text: 'Empresa', value: 'company.name', width: '20%' });
    }
    return items;
  }

  public goToFormEdit(sellingpoint: SellingPoint) {
    this.$router.push({
      name: 'selling_point-edit',
      params: {
        id: sellingpoint.id,
      },
    });
  }

  public goToFormCreate() {
    this.$router.push({
      name: 'selling_point-create',
    });
  }

  public async getSellingPoints() {
    const sellingpoints = await sellingpointService.getAllSellingPoints();
    this.sellingpoints = sellingpoints.map(
      (sellingpoint: SellingPoint) => new SellingPoint(sellingpoint),
    );
  }

  public async backPagination() {
    if (!sellingpointService.simplePaginationService.prevLink) return;
    const sellingpoints = await sellingpointService.simplePaginationService.prev();
    this.sellingpoints = sellingpoints.map(
      (sellingpoint: SellingPoint) => new SellingPoint(sellingpoint),
    );
  }

  public async nextPagination() {
    if (!sellingpointService.simplePaginationService.nextLink) return;
    const sellingpoints = await sellingpointService.simplePaginationService.next();
    this.sellingpoints = sellingpoints.map(
      (sellingpoint: SellingPoint) => new SellingPoint(sellingpoint),
    );
  }

  public async goToFirst() {
    if (!sellingpointService.simplePaginationService.firstLink) return;
    const sellingpoints = await sellingpointService.simplePaginationService.goToFirst();
    this.sellingpoints = sellingpoints.map(
      (sellingpoint: SellingPoint) => new SellingPoint(sellingpoint),
    );
  }

  public async created() {
    this.getSellingPoints();
  }
}
